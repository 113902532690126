import React from "react";
import Logo from "../assets/strip.png";
import PlayStoreLogo from "../assets/play-store.png";
import ScreenShot1 from "../assets/screenshots/image1.png";
import ScreenShot2 from "../assets/screenshots/image2.png";
import ScreenShot3 from "../assets/screenshots/image3.png";
export default function Home() {
  return (
    <div className="text__align_center">
      <img src={Logo} alt="finda logo" className="logo" />
      <div className="container">
        <h3 className="hero-title">
          <span className="brand__name">Finda</span> is a shopping app, that
          allows you shop across trusted vendors on instagram.
        </h3>
      </div>

      <div className="mt-5">
        <a href="https://play.google.com/store/apps/details?id=co.appstate.finda">
          <img src={PlayStoreLogo} width={135} alt="Playstore logo" />
        </a>
      </div>
      <p>Finda is currently only available on andriod.</p>
      <div className="screenshoot__list d-flex justify-content-center flex-wrap mt-5">
        <img
          src={ScreenShot1}
          className="img__screenshots p-5 rounded remove-md-xl"
          alt="screenshot account"
        />
        <img
          src={ScreenShot2}
          className="img__screenshots p-5 rounded"
          alt="screenshot account"
        />
        <img
          src={ScreenShot3}
          className="img__screenshots p-5 rounded remove-md-xl"
          alt="screenshot account"
        />
      </div>
      <p className="mb-3">©2021 Appstate Technology.</p>
      <br/>
    </div>
  );
}