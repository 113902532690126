import React, { useState, useEffect } from "react";
import axios from "axios";
import Logo from "../assets/strip.png";

export default function Results() {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    axios
      .get("https://api.appstate.co/v1/instashop/products_list/?q=iphone+11")
      .then((res) => {
        setData(res.data.products_list);
      });
  }, []);

  const onChange = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .get(`https://api.appstate.co/v1/instashop/products_list/?q=${search}`)
      .then((res) => {
        setData(res.data.products_list);
      });
  };

  return (
    <div>
      <div className="nav">
        <div className="d-flex justify-content-center">
          <img src={Logo} width={"50"} height={50} alt="logo" />
          <form onSubmit={handleSubmit}>
            <input
              name="search"
              placeholder="Search for phone, laptop."
              onChange={onChange}
            />
          </form>
        </div>
        <ul className="nav-items">
          <li>Search</li>
          <li>Sign up</li>
          <li>Sign in</li>
        </ul>
      </div>
      <div className="grid">
        {data.map((productData, index) => (
          <div key={index} className="product-card">
            <div
              className="product-image-background"
              style={{
                backgroundImage: `url(${productData.picture})`,
                height: "350px",
                margin: "5px",
                borderRadius: "5px",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
}
