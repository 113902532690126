import React from "react";

/**
 * Changes depending the state value being returned
 *
 * @export
 * @param {*} {
 *   children -- The child component being passed in,
 *   fallBackLoading,
 *   fallBackError,
 *   isLoading,
 *   isError
 * }
 * @returns React component
 */
export default function Suspence({
  children,
  fallBackLoading,
  fallBackError,
  fallBackEmpty,
  fallBackAccess,
  isLoading,
  isEmpty,
  isAccess,
  isError
}) {
    if (isError)
        return <div>{fallBackError}</div>;
    else if(isLoading)
        return <div>{fallBackLoading}</div>
    else if(isEmpty)
        return <div>{fallBackEmpty}</div>
    else if(isAccess)
        return <div>{fallBackAccess}</div>
    else
        return <div>{children}</div>
}
