import React from "react";

export default function LoadingPlaceholder({ width }) {
  return (
    <div>
      <div className="bg-white p-3 rounded text-center" style={width}>
        <div className="animated-background" style={{"height":"25px"}}></div>
        <p></p>
        <div className="animated-background" style={{"height":"76px"}}></div>
        <p></p>
        <div className="animated-background" style={{"height":"40px"}}></div>
      </div>
    </div>
  );
}
