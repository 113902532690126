import axios from "axios";
import baseUrl from "../const/base";

export const axiosAuth = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json"
  }
});

axiosAuth.interceptors.request.use(function(config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export const axiosUnAuth = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json"
  }
});
