import React from "react";
import { Route, Switch } from "react-router-dom";
import Search from "../pages/search";
import Results from "../pages/results";
import VerifyUser from "../pages/verifyUser";
import Home from "../pages/home";

export default function Routers() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/results" component={Results} />
      <Route path="/validate/:slug" component={VerifyUser} />
    </Switch>
  );
}
