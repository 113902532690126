import { GoX } from "react-icons/go";
import React, { useEffect, useState } from "react";
import { axiosUnAuth } from "../services/axiosInstance";
import Suspence from "../components/suspence.components";
import LoadingPlaceholder from "../components/loadingPlaceholder.components";
import Lottie from "lottie-react-web";
import animation from '../assets/lottie/lf30_editor_klzevqs5.json';

export default function VerifyUser({ match }) {
  const { slug } = match.params;
  const [data, setData] = useState({
    loading: true,
    error: false,
    data: {},
  });

  useEffect(() => {
    axiosUnAuth
      .get(`auth/verify_token/${slug}/`)
      .then((res) => {
        setData((data) => ({
          ...data,
          data: res.data,
          loading: false,
        }));
      })
      .catch((error) => {
        setData(
          (data) => ({
            ...data,
            data: {
              status: error.response?.status ?? "Error",
              message: error.response?.data,
            },
            loading: false,
            error: true,
          }),
        );
      });
  }, [slug]);

  return (
    <div className="mx-width-500 margin-auto p-5 mt-10 bg-white">
      <Suspence
        isLoading={data.loading}
        isError={data.error}
        fallBackLoading={<LoadingPlaceholder />}
        fallBackError={
          <div className="p-5 rounded text-center bg-white">
            <p>There is an issue in validating your token</p>
          </div>
        }
      >
        <Lottie
          width={100}
          options={{
            animationData: animation,
          }}
        />
        <p>
          {data?.data?.detail}.
        </p>
      </Suspence>
    </div>
  );
}
