import "./index.css";
import Home from "./pages/home";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Router from "./routes/routers";
const parsedData =
  window.location.host.split(".").length === 3
    ? window.location.host.split(".")[0]
    : "home";

const returnSubComponents = (data) => {
  switch (data) {
    case "admin":
      return <Router />;
    case "www":
      return <Router />;
    default:
      return <Router />;
  }
};

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      {returnSubComponents(parsedData)}
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
